<template>
  <overlay
    :show="loading"
    class-name="content-wrapper"
  >
    <PageHeader
      :screen-name="form.nome"
      :link-items="linkItems"
    />

    <ErrorModal
      :modal-show="modalError.showModal"
      :type-modal="modalError.typeModal"
      :case-modal="modalError.caseModal"
      @confirmed="redirecionarLista"
    />

    <main class="card">
      <HeaderEmpresa
        :empresa="empresa"
        @confirmed="resolveRoute"
      />
      <section class="form p-2">
        <b-form>
          <b-row>
            <b-col
              lg="4"
              md="4"
              sm="12"
            >
              <b-form-group
                label="CPF"
                label-class="font-weight-bold"
              >
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.cpf | VMask('###.###.###-##') }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col
              lg="4"
              md="4"
              sm="12"
            >
              <b-form-group
                label="Nome completo"
                label-class="font-weight-bold"
              >
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.nome }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group label="Nome da mãe" label-class="font-weight-bold">
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.nome_mae }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col
              lg="4"
              md="4"
              sm="12"
            >
              <b-form-group
                label="Sexo"
                label-class="font-weight-bold"
              >
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.genero ? form.genero : '-' }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col
              lg="4"
              md="4"
              sm="12"
            >
              <b-form-group
                label="Data de nascimento"
                label-class="font-weight-bold"
              >
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.data_nascimento }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group
                label="Nacionalidade"
                label-class="font-weight-bold"
              >
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.descricao_nacionalidade }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
            <b-col
              lg="4"
              md="4"
              sm="12"
            >
              <b-form-group
                label="Email"
                label-class="font-weight-bold"
              >
                <vue-perfect-scrollbar
                  class="altura_max_input_scrollbar"
                  :settings="settings"
                >
                  <div>
                    {{ form.email }}
                  </div>
                </vue-perfect-scrollbar>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </section>
      <section
        v-if="editable"
        class="buttons p-2"
      >
        <b-button
          id="edit-aviso"
          variant="custom-blue"
          @click.prevent="redirectToEdit()"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span class="align-middle">Editar</span>
        </b-button>
      </section>
    </main>
  </overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moment from 'moment'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import HeaderEmpresa from '@/views/components/custom/page-header/Header-empresa.vue'
import { getCompanyUserId } from '@/views/custom-pages/gerenciarUsuarios/requests'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'

export default {
  title: 'Detalhes usuário da empresa',
  components: {
    Overlay,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    PageHeader,
    VuePerfectScrollbar,
    ErrorModal,
    HeaderEmpresa,
  },

  data() {
    return {
      loading: true,

      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },
      linkItems: [
        {
          name: 'Empresas',
          routeName: 'empresa-usuario-list',
        },
        {
          name: 'Carregando dados...',
          active: true,
        },
        {
          name: 'Carregando dados...',
          active: true,
        },
      ],
      today: moment().format('YYYY-MM-DD'),
      editable: true,
      modalError: {
        showModal: false,
        typeModal: 'error',
        caseModal: 'idInexistente',
      },
      form: {
        id_usuario: '',
        cpf: '-',
        nome: '-',
        genero: '-',
        data_nascimento: '-',
        editavel: '',
        email: '-',
      },
      empresa: {
        id_pessoa: '',
        nome: '',
      },
    }
  },
  mounted() {
    if (!this.$route.params.idUsuario) {
      this.openModalError()

      return
    }

    this.index()
  },

  methods: {
    async index() {
      this.loading = true

      await getCompanyUserId(this.$route.params.idUsuario).then(({ data }) => {
        this.preenchimentoFormulario(data)
      })

      this.loading = false
    },

    preenchimentoFormulario(data) {
      this.form.id_usuario = this.$route.params.idUsuario
      this.form.cpf = data.cpf
      this.form.nome = data.nome
      this.form.genero = data.sexo
      this.form.data_nascimento = moment(data.data_nascimento).format('DD/MM/YYYY')
      this.form.email = data.email
      this.form.nome_mae = data.nome_mae != null ? data.nome_mae : "-";
      this.form.id_nacionalidade = data.id_nacionalidade;
      this.form.descricao_nacionalidade =
        data.descricao_nacionalidade != null
          ? data.descricao_nacionalidade
          : "-";

      this.linkItems[2].name = `Editar ${this.form.nome}`
      if (this.$route.params.empresa) {
        this.empresa = this.$route.params.empresa
        this.linkItems[1].name = this.empresa.nome
      } else {
        this.redirectToList()
      }
    },
    redirecionarLista() {
      this.$router.push({ name: 'empresa-usuario-list' })
    },

    openModalError() {
      this.modalError.showModal = true
    },
    redirectToEdit() {
      this.$router.push(
        {
          name: 'empresa-usuario-edit',
          params: {
            idUsuario: this.form.id_usuario,
            empresa: this.empresa,
          },
        },
      )
    },
    resolveRoute(route) {
      if (route !== this.$route.name) {
        this.$router.push(
          {
            name: route,
            params: {
              empresa: this.empresa,
            },
          },
        )
      }
    },
  },
}
</script>

<style scoped>
.altura_max_input_scrollbar{
    max-height:32px;
    word-break: break-all;
    padding-right: 15px;
}
</style>
